import type { FC } from 'react';

// Externals
import { Box, Container, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Helmet } from 'react-helmet-async';
import { t } from '@lingui/macro';

const NotFound: FC = () => {
	return (
		<>
			<Helmet>
				<title>FreewayTeam &bull; 404</title>
			</Helmet>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					minHeight: '100%',
					px: '2vw',
					py: 'calc(8vh + 56px)'
				}}
			>
				<Container
					disableGutters={true}
					maxWidth={false}
					sx={{
						zIndex: 1
					}}
				>
					<Grid container={true} spacing={3}>
						<Grid size={{ xs: 12, md: 6, lg: 5, xl: 4 }}>
							<Stack direction="column" spacing={6}>
								<Stack direction="column" spacing={2}>
									<Typography color="primary" variant="overline">
										{t`404: La page que vous recherchez n'est pas ici`}
									</Typography>
									<Typography color="textPrimary" variant="h1">
										{t`Soit vous avez entré une URL incorrecte, soit vous êtes venu ici par erreur. Quoi qu'il en soit,
                    essayez d'utiliser la navigation.`}
									</Typography>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
};

export default NotFound;
