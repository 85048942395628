import React from 'react';

// Externals
import { NoSsr } from '@mui/material';

// Components
import NotFound from 'components/legacy/screens/NotFound';
import AuthenticationLayout from 'components/legacy/layouts/authentication';

// Utils

// I18N

// import plural rules for all locales

// Store

const Page = () => {
	return (
		<NoSsr>
			<AuthenticationLayout>
				<NotFound />
			</AuthenticationLayout>
		</NoSsr>
	);
};

export default Page;
